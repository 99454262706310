/******************************************************************************/
/* This polyfill is currently a STUB to prevent errors in unsupported browsers*/
/******************************************************************************/
window.Notification = window.Notification || ( function() {
    function NotificationPolyfill(title, options){
        console.log('Options: ', options);

        this.actions = options.actions;
        this.badge;
        this.body = options.body;
        this.data;
        this.dir = options.dir;
        this.lang = options.lang;
        this.tag = options.tag;
        this.icon = options.icon;
        this.image = options.image;
        this.renotify;
        this.requireInteraction;
        this.silent;
        this.timestamp;
        this.title = title;
        this.vibrate;
    }

    NotificationPolyfill.permission = 'granted' | 'denied' | 'default';
    NotificationPolyfill.maxActions = 10;
    NotificationPolyfill.requestPermission = function() {};
    NotificationPolyfill.prototype.onlick = function(){};
    NotificationPolyfill.prototype.onclose = function(){};
    NotificationPolyfill.prototype.onerror = function(){};
    NotificationPolyfill.prototype.onshow = function(){};
    NotificationPolyfill.prototype.close = function(){};

    return NotificationPolyfill;
}());